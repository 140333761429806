input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

.hidden {
  display: none;
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.img-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 91.66%;
  margin-left: auto;
  margin-right: auto;
}

.btn {
  appearance: none;
  border: none;
  border-radius: 0.25rem;
  background-color: var(--tectonic-black-light);

  font-size: 0.875rem;
  color: var(--tectonic-white);
  letter-spacing: 0.4px;

  text-transform: uppercase;

  padding-top: 0.812rem;
  padding-right: 0.875rem;
  padding-bottom: 0.812rem;
  padding-left: 0.875rem;

  min-width: 6.25rem;
}

.default-input {
  font-size: 0.875rem;
  font-weight: 400;

  padding-top: 0.65rem;
  padding-right: 1rem;
  padding-bottom: 0.65rem;
  padding-left: 1rem;

  border-radius: 0.25rem;
  border: 1px solid var(--tectonic-black-light);

  &::placeholder {
    color: var(--tectonic-black-lighter);
    opacity: 0.4;
  }
}

.default-input--error {
  border: 1px solid red;
}

.black-logo {
  fill: var(--tectonic-black);
}

.logo {
  fill: var(--tectonic-white);
}

br {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}


iframe {
  display: none !important;
}
