:root {

  --tectonic-black: #000000;
  --tectonic-black-light: #101923;
  --tectonic-black-lighter: #141414;

  --tectonic-white: #FFFFFF;

  --tectonic-green: #0FB52B;

  --tectnoic-blue-dark: #060a0e;

  --tectonic-gray: #A1A1A1;
  --tectonic-gray-light: #ced4da;
  --tectonic-creamy:#E1E1E1;

}

