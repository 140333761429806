.confirm-popup {
  position: relative;
  z-index: 1;

  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 2rem;
  padding-right: 3.125rem;
  padding-bottom: 2rem;
  padding-left: 3.125rem;

  width: 25%;
  min-height: 12rem;
  min-width: 18rem;

  background-color: var(--tectonic-white);
  border-radius: 1.875rem;
  border: 1px solid rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 768px) {
    min-width: unset;

    width: 90%;
  }
}

.confirm-popup__title {
  margin-bottom: 2rem;

  font-size: 1.3rem;
  color: #101923;

  text-align: center;
}

.confirm-popup__block {
  display: flex;
}

.confirm-popup__btn {
  appearance: none;
  background-color: transparent;

  border: 1px solid var(--tectonic-green);

  min-width: 7rem;

  &:first-child {
    margin-right: 0.875rem;
  }
}

.confirm-popup__btn--cancel {

}

.confirm-popup__btn--accept {
  background-color: var(--tectonic-green);

  color: var(--tectonic-white);
}
