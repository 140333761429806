@font-face {
  font-family: 'Nexa';
  src: url('../fonts/Nexa-Bold.woff2') format('woff2'),
  url('../fonts/Nexa-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nexa';
  src: url('../fonts/Nexa-Regular.woff2') format('woff2'),
  url('../fonts/Nexa-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nexa';
  src: url('../fonts/Nexa-Black.woff2') format('woff2'),
  url('../fonts/Nexa-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nexa';
  src: url('../fonts/Nexa-Book.woff2') format('woff2'),
  url('../fonts/Nexa-Book.woff') format('woff');
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Nexa', Arial, sans-serif;
}
