html {
  box-sizing: border-box;

  font-size: clamp(10px, 0.83vw, 100px);
}

html.active {
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  min-width: 280px;
}

.wrapper-outer {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}

.wrapper {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
}

.footer {
  flex-shrink: 0;
}
