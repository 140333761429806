.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;

  padding-top: 2rem;
  padding-bottom: 2rem;

  display: flex;

  overflow-y: auto;

  height: 100%;
  width: 100%;
}

.popup_background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.5);
}
