.dashboard {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 8rem;
  padding-bottom: 2rem;

  background-color: #101923;

  min-height: 100vh;
}

.dashboard-content {
  min-height: 50rem;
  width: 80%;

  background-color: var(--tectonic-black-light);

  border-radius: 0.875rem;
  border: 2px solid var(--tectonic-green);

  @media screen and (max-width: 667px) {
    width: 90%;

    min-width: unset;
  }
}

.dashboard-tab {
  display: flex;

  overflow: hidden;
  border-radius: 0.875rem 0.875rem 0 0;
}

.dashboard-tab__btn {
  appearance: none;
  border: none;
  background-color: transparent;

  cursor: pointer;

  border-bottom: 2px solid var(--tectonic-green);

  font-size: 1.25rem;
  color: var(--tectonic-white);

  padding-top: 0.875rem;
  padding-right: 0.5rem;
  padding-bottom: 0.875rem;
  padding-left: 0.5rem;

  width: 50%;
}

.dashboard-tab__btn:first-child {
  border-right: 2px solid var(--tectonic-green);
}

.dashboard-tab__btn--active {
  background-color: var(--tectonic-green);
}

.dashboard-create {
  padding-top: 1.5rem;
  padding-right: 1.2rem;
  padding-bottom: 1.5rem;
  padding-left: 1.2rem;
}

.dashboard-create__form {
  margin-bottom: 2rem;
}

.dashboard-create__line {
  display: flex;
  align-items: flex-end;
}

.dashboard-create__line:not(:last-child) {
  margin-bottom: 1rem;
}

.dashboard-create__label {
  display: flex;
  flex-direction: column;

  margin-right: 0.5rem;

  width: 100%;
}

.dashboard-create__name {
  font-size: 0.875rem;
  color: var(--tectonic-white);

  margin-bottom: 0.2rem;
}

.dashboard-create__input {
  appearance: none;
  min-width: unset;

  color: var(--tectonic-white);

  background-color: var(--tectnoic-blue-dark);
}

.dashboard-create__input:focus {
  outline: none;
  box-shadow: 0 0 0 2px #FFFFFF;
}

.dashboard-create__submit {
  margin-left: 0.5rem;

  display: flex;
  align-items: flex-end;

  align-self: stretch;
}

.dashboard-create__submit-btn {
  appearance: none;
  border: 1px solid var(--tectonic-green);

  font-size: 1rem;

  padding-top: 0.53rem;
  padding-bottom: 0.53rem;

  background-color: var(--tectnoic-blue-dark);
}

.dashboard-create__list {
  margin: 0;
  padding: 0;
  list-style: none;

  position: relative;

  overflow-y: auto;
  max-height: 38rem;

  border: 1px solid var(--tectonic-green);
}

.dashboard-create__item {
  display: flex;
  align-items: center;
}

.dashboard-create__item-column {
  display: flex;
  align-items: center;
  align-self: stretch;

  width: 100%;

  color: #FFFFFF;

  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;

  &:not(:first-child) {
    border-left: 1px solid var(--tectonic-green);
  }
}

.dashboard-create__item--header {
  .dashboard-create__item-column {
    color: var(--tectonic-green);

    border-bottom: 1px solid var(--tectonic-green);
  }
}

.dashboard-create__item-delete__btn {
  position: relative;
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0;

  width: 2rem;
  flex-shrink: 0;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  svg {
    max-height: 100%;
    max-width: 100%;
    fill: var(--tectonic-white);
  }
}

.dashboard-create__list--user {
  .dashboard-create__item-column--id {
    width: 5rem;
    flex-shrink: 0;
  }

  .dashboard-create__item-column--username {
    width: 20%;
    flex-shrink: 0;
  }

  .dashboard-create__item-column--password {
    flex-grow: 1;
  }

  .dashboard-create__item-column--delete {
    width: 3rem;
    flex-shrink: 0;
  }
}

.dashboard-create__list--email {
  .dashboard-create__item-column--id {
    width: 5rem;
    flex-shrink: 0;
  }

  .dashboard-create__item-column--username {
    flex-grow: 1;
  }

  .dashboard-create__item-column--delete {
    width: 3rem;
    flex-shrink: 0;
  }
}

.dashboard-logout {
  //position: absolute;
  //top: 1.5rem;
  //right: 1.5rem;
  //z-index: 1;
}

.dashboard-logout__btn {
  appearance: none;
  border: none;
  background-color: transparent;

  padding-top: 0.2rem;
  padding-right: 0.875rem;
  padding-bottom: 0.2rem;
  padding-left: 0.875rem;

  font-size: 1.25rem;
  color: var(--tectonic-white);
}
